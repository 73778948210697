import { graphql } from 'gatsby'
import React, { useEffect, useState } from "react" 
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import cover from "../../assets/img/vasque/cover.jpg"
import img1 from "../../assets/img/vasque/img-1.jpg"
import img2 from "../../assets/img/vasque/img-2.jpg"
import img3 from "../../assets/img/vasque/img-3.jpg"
import img4 from "../../assets/img/vasque/img-4.jpg"
import img5 from "../../assets/img/vasque/img-5.jpg"

const VasquePage = ({ data }) => {
    
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const images = data.gallery.edges.map(({ node }) => node.childImageSharp)
  return(
      <Layout>
        <SEO title="Vasque" />
        <div class="w-full relative">
            <div className="slider-wrapper">
              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">
                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
        <div class="md:mx-40 md:my-5 mx-3">
        <div class="grid grid-cols-1 mt-8 gap-5">
          <h1 class="text-center text-2xl font-bold uppercase mt-5">VASQUE DE SALLE DE BAIN EN SOLID SURFACE</h1>
          <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
        </div>
        <div class="grid grid-cols-1 mt-8">
           <img src={cover} alt="cover" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 ">
            <div> <p class="font-bold mb-2">Salle de bain: une vasque parfaitement adaptée à votre projet</p>
               <div class="border-solid border-2 border-red-500 w-12 mb-5"></div>
              <p class="mb-4"> Comme tout dessin d’intérieur, vos conceptions de salles de bain sont l’occasion de mettre en œuvre votre style unique.</p>
              <p class="mb-4">
                Solid Surface offre variété, longévité et élégance pour concrétiser vos idées créatives.
              </p> 
              <p>Une vasque en Solid Surface est un vrai plus dans un dessin de salle de bain, obéissant à votre imagination. Outre la création de vos propres vasques, vous pouvez également faire votre choix parmi nos nombreuses dimensions et formes standard.</p>
             </div>
            <div>  <img src={img1} alt="1" /> </div>
        </div>
        <div>
              <p class="font-bold mb-2">Si vous pouvez l'imaginer, vous pouvez le créer</p>
               <div class="border-solid border-2 border-red-500 w-12 mb-5"></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
          <div><img src={img2} alt="2" /></div>
          <div><img src={img3} alt="3" /></div>
        </div>
        <div>Quel que soit votre projet de salle de bain, les vasques, les meubles de toilette intégrés et autres fonctionnalités sur mesure en Solid Surface sont faciles à intégrer à vos plans. La polyvalence qu’ils offrent ouvre la voie à des solutions personnalisées de toute taille, forme, style ou couleur.</div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
          <div>
               <p class="font-bold mb-2">Intégration sans joints</p>
               <div class="border-solid border-2 border-red-500 w-12 mb-5"></div>
               <p class="my-7">L’un des principaux avantages de Solid Surface est la possibilité de créer une surface complète et continue qui incorpore des lavabos ou des vasques. Les techniques de montage sous plan et sans joint éliminent les espaces dans lesquels s’accumulent la salissure et l’eau, et réduisent la fréquence de nettoyage et d’entretien.</p>
               <img src={img5} alt="5" />
          </div>
          <div><img src={img4} alt="4" />
               <p class="font-bold mb-2">Adaptés à vos besoins</p>
               <div class="border-solid border-2 border-red-500 w-12 mb-5"></div>
           <p>Que vous optiez pour une vasque standard ou personnalisée, vous pouvez compter sur ce qu’il y a de mieux en matière de performance, polyvalence et facilité d’entretien. Une vasque de salle de bain en Solid Surface est facile à nettoyer et bénéficie d’une durée de vie au moins aussi longue que celle de la salle de bain. Par ailleurs, avec un nettoyage approprié, Solid Surface ne favorise pas le développement des moisissures.</p>
          </div>
        </div>
        <div class="gl gl33">
          <Gallery images={images} />
        </div>
        </div>
    </Layout>
)
}
export const query = graphql`
query VasqueGallery {
    gallery:allFile (filter:{relativeDirectory: {eq: "img/vasque/g"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld:allFile (filter:{relativeDirectory: {eq: "img/vasque/s"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
export default VasquePage